// eslint-disable-next-line import/no-extraneous-dependencies
import { IEventProperty } from '@microsoft/1ds-analytics-web-js';

export enum ViewTriggeredEventTypeEnum {
  HASHCHANGE = 'hashchange',
  LOAD = 'load',
  POPSTATE = 'popstate',
  INTERVALCHECK = 'intervalcheck',
}

// here list all populated used customized fields in nezha
export interface NezhaCustomizedFields {
  error?: string | IEventProperty;
  // scenarios: used for all View Scenarios, the value could be: hashchange, load, popstate
  viewTriggeredEventType?: string | IEventProperty;
  // scenarios: dashboard, chart, cache, etc.
  datasourceId?: string | IEventProperty;
  // scenarios: dashboard, chart, sqllab, etc.
  databaseId?: string | IEventProperty;
  // scenarios: dashboard, chart, etc.
  sliceId?: string | IEventProperty;
  // scenarios: dashboard
  dashboardId?: string | IEventProperty;
  // scenarios: sqllab
  //    queryId in sqllab may also called clientId/client_id
  queryId?: string | IEventProperty;
  // scenarios: sqllab
  sqlQurey?: string | IEventProperty;
  // scenarios: dashboard, chart, etc.
  formData?: string | IEventProperty;
  // scenarios: wukong page
  prompts?: string | IEventProperty;
  // scenarios: wukong page
  promptsReponse?: string | IEventProperty;
  // scenarios: wukong page
  rateType?: string | IEventProperty;
  // scenarios: api call
  apiUri?: string | IEventProperty;
  // scenarios: scenarios + operation in(change, add, delete, discard)
  content?: string | IEventProperty;
  // scenarios: table column(change, add, delete, discard)
  columnId?: string | IEventProperty;
}

/**
 * Don't remove this interface, it's a document to help you understand the original performance entries
 * original performance entries
 * @param startTime: The timestamp when the navigation request started (in milliseconds since the navigation start).
 * @param duration: The total time taken for the navigation request (in milliseconds).
 * @param workerStart: The timestamp when the worker started (if applicable).
 * @param redirectStart and redirectEnd: Timestamps for any redirects (not applicable in this case).
 * @param fetchStart: The timestamp when fetching the document started.
 * @param domainLookupStart and domainLookupEnd: Timestamps for DNS lookup.
 * @param connectStart, secureConnectionStart, and connectEnd: Timestamps for establishing the connection.
 * @param requestStart: The timestamp when the server received the request.
 * @param responseStart: The timestamp when the server started sending the response.
 * @param responseEnd: The timestamp when the response finished.
 * @param transferSize: The size of the transferred content (in bytes).
 * @param encodedBodySize: The size of the response body after encoding (in bytes).
 * @param decodedBodySize: The size of the response body after decoding (in bytes).
 * @param unloadEventStart and unloadEventEnd: Timestamps for unloading the previous document (if applicable).
 * @param domInteractive: The timestamp when the DOM became interactive.
 * @param domContentLoadedEventStart and domContentLoadedEventEnd: Timestamps for DOMContentLoaded event.
 * @param domComplete: The timestamp when the DOM was fully loaded.
 * @param loadEventStart and loadEventEnd: Timestamps for load event.
 * @param redirectCount: The number of redirects (0 in this case).
 */
// interface PerformanceOriginalEntries {
//   startTime: string | IEventProperty;
//   duration: string | IEventProperty;
//   workerStart: string | IEventProperty;
//   fetchStart: string | IEventProperty;
//   domainLookupStart: string | IEventProperty;
//   domainLookupEnd: string | IEventProperty;
//   connectStart: string | IEventProperty;
//   connectEnd: string | IEventProperty;
//   secureConnectionStart: string | IEventProperty;
//   requestStart: string | IEventProperty;
//   responseStart: string | IEventProperty;
//   responseEnd: string | IEventProperty;
//   transferSize: string | IEventProperty;
//   encodedBodySize: string | IEventProperty;
//   decodedBodySize: string | IEventProperty;
//   unloadEventStart: string | IEventProperty;
//   unloadEventEnd: string | IEventProperty;
//   domInteractive: string | IEventProperty;
//   domContentLoadedEventStart: string | IEventProperty;
//   domContentLoadedEventEnd: string | IEventProperty;
//   domComplete: string | IEventProperty;
//   loadEventStart: string | IEventProperty;
//   loadEventEnd: string | IEventProperty;
//   redirectCount: string | IEventProperty;
// }

/**
 * For performance.getEntriesByType('navigation') returned object
 * @param redirectTime: redirectEnd - redirectStart
 * @param dnsLookupTime: domainLookupEnd - domainLookupStart
 * @param tcpConnectTime: connectEnd - connectStart
 * @param timeToFirstByte: responseStart - requestStart
 * @param responseTime: responseEnd - responseStart
 * @param domContentLoadedTime: domContentLoadedEventEnd - domContentLoadedEventStart
 * @param domProcessingTime: domContentLoadedEventEnd - fetchStart
 * @param loadTime: loadEventEnd - startTime
 * @param transferSize: The size of the transferred content (in bytes).
 * @param encodedBodySize: The size of the response body after encoding (in bytes).
 * @param decodedBodySize: The size of the response body after decoding (in bytes).
 * @param performanceOriginalEntries: original performance entries @see PerformanceOriginalEntries
 */
export interface NezhaPerformanceCustomizedFields {
  // duration for any redirects
  redirectTime?: string | IEventProperty;
  // duration for dns lookup
  dnsLookupTime?: string | IEventProperty;
  // duration for tcp connect
  tcpConnectTime?: string | IEventProperty;
  // time to first byte, for example connect and wait for backend response till first byte received
  timeToFirstByte?: string | IEventProperty;
  // duration for response
  responseTime?: string | IEventProperty;
  // duration for dom contend loaded
  domContentLoadedTime?: string | IEventProperty;
  // duration for page load
  loadTime?: string | IEventProperty;
  // duration for dom processing
  domProcessingTime?: string | IEventProperty;
  // The size of the transferred content (in bytes).
  transferSize?: string | IEventProperty;
  // The size of the response body after encoding (in bytes).
  encodedBodySize?: string | IEventProperty;
  // The size of the response body after decoding (in bytes).
  decodedBodySize?: string | IEventProperty;
}

export interface NezhaPageViewCustomizedFields
  extends NezhaCustomizedFields,
    NezhaPerformanceCustomizedFields {}
