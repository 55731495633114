import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import { NezhaTelemetry } from 'src/telemetry/helper/CommonTelemetryHelper';
import { NezhaCustomizedFields, ViewTriggeredEventTypeEnum, NezhaPerformanceCustomizedFields, NezhaPageViewCustomizedFields } from '../constants/CustomizedEventsContants';
import { getUrl } from '../util';

const nezhaTrackPageView = (analytics:ApplicationInsights, customProperties: NezhaPageViewCustomizedFields) => {
  NezhaTelemetry.trackPageViewEvent(analytics, customProperties);
};

const NEZHA_TRACKING_LAST_URL = 'NEZHA_TRACKING_LAST_PATHNAME';
const handlePathnameChange = (analytics:ApplicationInsights) => {
  const customProperties: NezhaCustomizedFields = {
    viewTriggeredEventType: ViewTriggeredEventTypeEnum.INTERVALCHECK,
  };
  nezhaTrackPageView(analytics, customProperties);
};

const checkPathnameChange = (analytics:ApplicationInsights) => {
  const currentURL = getUrl();
  const lastURL = sessionStorage.getItem(NEZHA_TRACKING_LAST_URL);
  if (currentURL !== lastURL) {
    sessionStorage.setItem(NEZHA_TRACKING_LAST_URL, currentURL);
    handlePathnameChange(analytics);
  }
}

export const startAutoCapturePageView = (analytics:ApplicationInsights) => {
  if(window.addEventListener) {
    window.addEventListener('load', function() {
      const currentURL = getUrl();
      const customProperties: NezhaCustomizedFields = {
        viewTriggeredEventType: ViewTriggeredEventTypeEnum.LOAD,
      };
      console.log('URL changed:', window.location.href);
      sessionStorage.setItem(NEZHA_TRACKING_LAST_URL, currentURL);

      // use a delay to run real track action. 
      // This is to fix that some of the values of Performance have not been set correctly when the onload event function is called.
      setTimeout(function() {
        try {
          const x = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
          // somes alias to make code more readable
          const f = (input:number):string => Number.isNaN(input) ? '-1' : Math.floor(input).toString();
          const performanceMetrics: NezhaPerformanceCustomizedFields = {
            redirectTime: f(x.redirectEnd - x.redirectStart),
            dnsLookupTime: f(x.domainLookupEnd - x.domainLookupStart),
            tcpConnectTime: f(x.connectEnd - x.connectStart),
            timeToFirstByte: f(x.responseStart - x.requestStart),
            responseTime: f(x.responseEnd - x.responseStart),
            domContentLoadedTime: f(x.domContentLoadedEventEnd - x.domContentLoadedEventStart),
            loadTime: f(x.loadEventEnd - x.startTime),
            domProcessingTime: f(x.domContentLoadedEventEnd - x.fetchStart),
            transferSize: f(x.transferSize),
            encodedBodySize: f(x.encodedBodySize),
            decodedBodySize: f(x.decodedBodySize),
          };  
          console.debug("Performance Entries:", x);
          console.log('Performance Metrics:', performanceMetrics);
          const combinedProperties: NezhaPageViewCustomizedFields = { ...performanceMetrics, ... customProperties };
          nezhaTrackPageView(analytics, combinedProperties);
        } catch (error) {
          console.error('Error in trackPageLoadPerformanceEvent:', error);
        };
      }, 100);

      // start Interval tracking after load event
      // add single page url change auto tracking, need to do more investigating
      setInterval(checkPathnameChange, 500, analytics);
    });

    // When the user navigates forward or backward using the browser’s history
    // (e.g., clicking the back or forward buttons), the URL changes without a full page reload.
    // We can detect these changes using the popstate event.
    //   For single page forward or backward
    window.addEventListener('popstate', function (event) {
      const currentURL = getUrl();
      const customProperties: NezhaCustomizedFields = {
        viewTriggeredEventType: ViewTriggeredEventTypeEnum.POPSTATE,
      };
      // The URL changed; handle it here
      console.log('URL changed:', window.location.href);
      sessionStorage.setItem(NEZHA_TRACKING_LAST_URL, currentURL);
      nezhaTrackPageView(analytics, customProperties);
    });

    window.addEventListener('hashchange', function() {
      const currentURL = getUrl();
      const customProperties: NezhaCustomizedFields = {
        viewTriggeredEventType: ViewTriggeredEventTypeEnum.HASHCHANGE,
      };
      // The URL hash changed; handle it here
      console.log('URL hash changed:', window.location.hash);
      sessionStorage.setItem(NEZHA_TRACKING_LAST_URL, currentURL);
      nezhaTrackPageView(analytics, customProperties);
    });
  }
};
