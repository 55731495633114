// eslint-disable-next-line import/no-extraneous-dependencies
import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import { NEZHA_COMMON_FIELDS_NAME, NEZHA_AREA, NEZHA_OPERATION, NEZHA_EVENT_PREFIX } from '../constants/CommonEventsContants';
import { NezhaCustomizedFields, NezhaPageViewCustomizedFields, NezhaPerformanceCustomizedFields } from '../constants/CustomizedEventsContants';
import { getMainEventName, getFieldsEventName, parseScenarioFromUri, getSearchParams, getPathname, getUri, getRefUri } from '../util';

const ENGAGEMENT_EVENT_TABLE_NAME = getMainEventName(NEZHA_EVENT_PREFIX.NEZHA, NEZHA_AREA.WEB_ENGAGEMENT);

export interface EngagementDataFieldsProps {
    scenario: string,
    operation: string, 
    contextId?:string, 
    targetUrl?:string, 
    customProperties?: NezhaCustomizedFields | NezhaPerformanceCustomizedFields,
}

function prepareEngagementDataFields(props: EngagementDataFieldsProps) {
  const { scenario, operation, contextId, targetUrl, customProperties } = props;
  // add uri to common fields
  const uriProp: string = getUri();

  // add refUri to common fields
  const refUriProp: string = getRefUri();
  let dataFields = {
    [NEZHA_COMMON_FIELDS_NAME.Uri]: uriProp,
    [NEZHA_COMMON_FIELDS_NAME.RefUri]: refUriProp,
    [NEZHA_COMMON_FIELDS_NAME.EventName]: getFieldsEventName(
      NEZHA_EVENT_PREFIX.NEZHA, NEZHA_AREA.WEB_ENGAGEMENT, props.scenario, props.operation
    ),
    [NEZHA_COMMON_FIELDS_NAME.Area]: NEZHA_AREA.WEB_ENGAGEMENT,
    [NEZHA_COMMON_FIELDS_NAME.Scenario]: scenario,
    [NEZHA_COMMON_FIELDS_NAME.Operation]: operation,
    [NEZHA_COMMON_FIELDS_NAME.ContextId]: contextId || "",
    [NEZHA_COMMON_FIELDS_NAME.TargetUrl]: targetUrl || "",
  };
  dataFields = Object.assign(dataFields, customProperties);
  return dataFields;
}

/**
 * A Common View event track fucntion for Nezha Web
 * @param analytics 
 * @param customProperties 
 */
export const trackPageViewEvent = (analytics: ApplicationInsights, customProperties: NezhaPageViewCustomizedFields) => {
  const scenario = parseScenarioFromUri(getPathname(), getSearchParams());
  const operation = NEZHA_OPERATION.VIEW;
  const props:EngagementDataFieldsProps = { scenario, operation, customProperties};
  const preparedData = prepareEngagementDataFields(props);
  // view event could add search params automatically
  preparedData[NEZHA_COMMON_FIELDS_NAME.SearchParams] = getSearchParams();
  const preparedName = ENGAGEMENT_EVENT_TABLE_NAME;
  analytics.trackEvent({
    name: preparedName,
    data: preparedData,
  });
};

/**
 * Common Engagement event track function for Nezha Web
 * @param analytics 
 * @param props 
 */
export const trackEngagementEvent = (analytics: ApplicationInsights, props: EngagementDataFieldsProps) => {
  const preparedData = prepareEngagementDataFields(props);
  const preparedName = ENGAGEMENT_EVENT_TABLE_NAME;
  analytics.trackEvent({
    name: preparedName,
    data: preparedData,
  });
};

/**
 * Common event track function for Nezha Web
 *   - This function could be used for all web events, include engagement or qos events
 * @param analytics 
 * @param eventTableName
 * @param props 
 */
export const trackEvent = (analytics: ApplicationInsights, eventTableName: string, props: EngagementDataFieldsProps) => {
  const preparedData = prepareEngagementDataFields(props);
  const preparedName = eventTableName;
  analytics.trackEvent({
    name: preparedName,
    data: preparedData,
  });
};

/**
 * Nezha Telemetry
 *  - trackPageViewEvent
 *  - trackEngagementEvent
 *    All web user engagement activity events could use this common function to track
 *  - trackEvent
 */ 
export const NezhaTelemetry = {
  trackPageViewEvent,
  trackEngagementEvent,
  trackEvent,
}; 