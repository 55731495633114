// the commom prefix for nezha events
export const NEZHA_EVENT_PREFIX = {
  NEZHA: 'Nezha',
};

// Area
export const NEZHA_AREA = {
  WEB_ENGAGEMENT: 'WebEngagement',
  WEB_QOS: 'WebQos',
};

// Scenario
export const NEZHA_SCENARIO = {
  WUKONG_COPILOT: 'WukongCopilot',
  DASHBOARD: 'Dashboard',
  CHART: 'Chart',
  CHART_SIGNAL: 'ChartSignal',
  COHORT: 'Cohort',
  WELCOME: 'Welcome',
  SQLLAB: 'Sqllab',
  DATABASE: 'Database',
  DATASET: 'Dataset',
  MEASURE: 'Measure',
  SAVED_COLUMN: 'SavedColumn',
  TABLE_COLUMN: 'TableColumn',
  KNOWLEDGE_LAB: 'KnowledgeLab',
  HELP: 'Help',
  SETTING: 'Setting',
  SAVED_QUERY: 'SavedQuery',
  USERS: 'Users',
  REGISTER_USER: 'RegisterUser',
  USER_PROFILE: 'UserProfile',
  ROLES: 'Roles',
  ACTION_LOG: 'ActionLog',
  ROW_LEVEL_SECURITY: 'RowLevelSecurity',
  CSS_TEMPLATE_MODEL_VIEW: 'CssTemplateModelView',
  TAGS: 'Tags',
  ALERT: 'Alert',
  REPORT: 'Report',
  ANNOTATION_LAYER: 'AnnotationLayer',
  SAVED_COLUMN_EXPLORE: 'SavedColumnExplore',
  CALCULATED_COLUMN_EXPLORE: 'CalculatedColumnExplore',
  SAVED_QUERY_EXPLORE: 'SavedQueryExplore',
  SAVED_MEASURE_EXPLORE: 'SavedMeasureExplore',
  SAVED_COHORT_LIST_EXPLORE: 'SavedCohortListExplore',
  SAVED_COHORT_EXPLORE: 'SavedCohortExplore',
  CHART_EXPLORE: 'ChartExplore',
  DATASET_EXPLORE: 'DatasetExplore',
  EXPLORE: 'Explore',
  UNCLASSIFIED: 'Unclassified',
};

export const NEZHA_OPERATION = {
  // Definition: PageView telemetry is logged when an application user opens a new page of a monitored application.
  // Context: The “Page” in this context is a logical unit defined by the developer, such as an application tab or a screen. It isn’t necessarily correlated to a browser webpage load or a refresh action1.
  VIEW: 'View',
  // Normal Click Event, track it manually by developer if needed
  CLICK: 'Click',
  // events (like “page_view”) are more general and capture page loads and user interactions.
  //    but Load activity contains the time duration of the page Load
  LOAD: 'Load',
  // Page Navigation Performance activity, contains details informations of the page navigation performance,
  // this action is designed for SPA(Single Page Application) navigation
  PAGE_SPA_NAVIGATION_PERFORMANCE: 'PageSPANavigationPerformance',
  // Open activity
  OPEN: 'Open',
  // Close activity
  CLOSE: 'Close',
  // Save activity
  SAVE: 'Save',
  // Update chart activity
  UPDATE_CHART: 'UpdateChart',
  // Update prompts activity
  UPDATE_PROMPTS: 'UpdatePrompts',
  // Rate prompts activity
  RATE_PROMPTS: 'RatePrompts',
  // Run Query activity
  RUN_QUERY: 'RunQuery',
  // Discard activity
  DISCARD: 'Discard',
  // Confirm activity
  CONFIRM: 'Confirm',
  // Add activity
  ADD: 'Add',
  // Remove activity
  REMOVE: 'Remove',
  // Change Activity if needed(should not trigger a huge change activity in a short time)
  CHANGE: 'Change',
  // Cancel activity
  CANCEL: 'Cancel',
  // Initilalize activity
  INITILALIZE: 'Initilalize',
  // Api activity
  API: 'Api',
  // UI Render activity
  RENDER: 'Render',
  // Submit activity
  SUBMIT: 'Submit',
  // user dwell activity
  DWELL: 'Dwell',
  // user wukong activity
  WUKONG_CONVERSATION: 'WukongConversation',
  // user wukong activity
  WUKONG_Refactor: 'WukongRefactor',
  // user wukong activity
  WUKONG_Generate: 'WukongGenerate',
  // send request
  SEND_REQUEST: 'SendRequest',
  // approve request
  APPROVE_REQUEST: 'ApproveRequest',
  // reject request
  REJECT_REQUEST: 'RejectRequest',
  // retry call
  RETRY_CALL: 'RetryCall',
  // Client Error, if some error happened in UX client side, should track this event
  CLIENT_ERROR: 'ClientError',
  // API Error, if some error happened in API side, should track this event
  API_ERROR: 'ApiError',
};

export const NEZHA_COMMON_FIELDS_NAME = {
  // Definition: mandatory name of an event, will be directly mapping to events name in Aria
  // Type: string
  //
  // Format `<NezhaEventPrefix>.<Area>.<Scenario>.<Operation>`
  // Sample `Nezha.WebEngagement.Chart.UpdateChart`
  EventName: 'EventName',
  // Definition: String literal type with predefined areas.
  // Type: string
  // Sample: WebEngagement,
  // Values of: @NEZHA_AREA
  // Mapping from: data.area
  Area: 'Area',
  // Definition: Custom scenario specific field. Scenario owners have the ability to decide a value.
  // Type: string
  // Format: [A-Z][a-zA-Z\.]+
  // Sample: Chart
  // Values of: NEZHA_SCENARIO
  Scenario: 'Scenario',
  // Definition: String literal type with mostly predefined operations.
  // Type: string
  // Format [A-Z][a-zA-Z]+
  // Sample: View
  // Values of: @NEZHA_OPERATION
  Operation: 'Operation',
  // Definition: The Events send from
  // Type: string
  // Format: ip or domain
  // Sample: test.microsoftnezha.com
  // Mapping from: ext.web.domain
  Endpoint: 'Endpoint',
  // Definition: The env type
  // Type: string
  // Format: parse from domain
  // Values of [test, test2, sdf, msit, localhost]
  // Mapping form: parseEnvFromDomain(ext.web.domain)
  Environment: 'Environment',
  // Definition: Url user is viewing
  // Type: string
  // Format: http://localhost:5000/explore/
  // Mapping from: data.uri
  Uri: 'Uri',
  // Definition: In various contexts, a refUri is used as a reference to another resource. if for page change case, General refUrl points to the previous url
  // Type: string
  // Format: http://localhost:5000/nezha/welcome
  // Mapping from: data.refUri
  RefUri: 'RefUri',
  // Definition: If exists, means the url value which a click action will change page to, if not have, this field can be empty string
  // Type: string
  // Format: http://localhost:5000/nezha/dashboard/567/
  // Mapping from: data.targetUri
  TargetUrl: 'TargetUrl',
  // Definition: After ? in url
  // Type: string
  // Format: slice_id=2&dashboard_id=1
  // Mapping from: data.searchParams
  SearchParams: 'SearchParams',
  // Definition: Client side context id that identifies the context of the event. if not have, this field can be empty string
  // Type: string
  // Format: should give by backend, if not have, this field can be empty string
  ContextId: 'ContextId',
  // Definition: The User Puid in Superset, (The user AadId is more good, but If we can got it need add to another field)
  // Type: string
  // Format: before hash -> 34, after PII hash -> dfasdf-dfasdf-dfasdf-dfasdf
  // Mapping from: data.UserPuid (If set)
  UserPuid: 'UserPuid',
};
