import {
  NEZHA_EVENT_PREFIX,
  NEZHA_SCENARIO,
} from './constants/CommonEventsContants';

export function getUri() {
  const currentUrl = window.location.href;
  // Remove any existing query parameters
  return currentUrl.split('?')[0];
}

export function getRefUri() {
  const currentUrl = window.document.referrer;
  // Remove any existing query parameters
  return currentUrl.split('?')[0];
}

export function getUriWithoutQueryParams(url: string) {
  return url.split('?')[0];
}

export function getUrl() {
  return window.location.href;
}

export function getPathname() {
  return window.location.pathname;
}

export function getEventName(
  area: string,
  scenario: string,
  operation: string,
) {
  return `${NEZHA_EVENT_PREFIX}.${area}.${scenario}.${operation}`;
}

export function getDomain() {
  return window.location.hostname;
}

export function getHost() {
  return window.location.host;
}

export function getSearchParams() {
  return window.location.search;
}

export function getEnvironment() {
  switch (window.location.hostname) {
    case 'www.microsoftnezha.com':
      return 'prod';
    case 'sdf.microsoftnezha.com':
      return 'sdf';
    case 'test.microsoftnezha.com':
      return 'test';
    case 'test2.microsoftnezha.com':
      return 'test2';
    case 'test3.microsoftnezha.com':
      return 'test3';
    case 'test4.microsoftnezha.com':
      return 'test4';
    default:
      if (window.location.hostname.startsWith('localhost')) {
        return 'localhost';
      }
      return '';
  }
}

export function getMainEventName(nezhaEventPreifx: string, area: string) {
  return `${nezhaEventPreifx}.${area}`;
}

export function getFieldsEventName(
  nezhaEventPreifx: string,
  area: string,
  scenario: string,
  operation: string,
) {
  return `${nezhaEventPreifx}.${area}.${scenario}.${operation}`;
}

export function parseScenarioFromUri(pathname: string, searchParams?: string) {
  if (pathname.startsWith('/nezha/welcome')) {
    return NEZHA_SCENARIO.WELCOME;
  }

  if (pathname.startsWith('/dashboard')) {
    return NEZHA_SCENARIO.DASHBOARD;
  }

  if (pathname.startsWith('/nezha/dashboard')) {
    return NEZHA_SCENARIO.DASHBOARD;
  }

  if (pathname.startsWith('/chart')) {
    return NEZHA_SCENARIO.CHART;
  }

  if (pathname.startsWith('/nezha/sqllab/history')) {
    return NEZHA_SCENARIO.SQLLAB;
  }

  if (pathname.startsWith('/nezha/sqllab')) {
    return NEZHA_SCENARIO.SQLLAB;
  }

  if (pathname.startsWith('/savedqueryview')) {
    return NEZHA_SCENARIO.SAVED_QUERY;
  }

  if (pathname.startsWith('/databaseview')) {
    return NEZHA_SCENARIO.DATABASE;
  }

  if (pathname.startsWith('/tablemodelview')) {
    return NEZHA_SCENARIO.DATASET;
  }

  if (pathname.startsWith('/dataset')) {
    return NEZHA_SCENARIO.DATASET;
  }

  if (pathname.startsWith('/savedColumn')) {
    return NEZHA_SCENARIO.SAVED_COLUMN;
  }

  if (pathname.startsWith('/measure')) {
    return NEZHA_SCENARIO.MEASURE;
  }

  if (pathname.startsWith('/users')) {
    return NEZHA_SCENARIO.USERS;
  }

  if (pathname.startsWith('/registeruser')) {
    return NEZHA_SCENARIO.REGISTER_USER;
  }

  if (pathname.startsWith('/roles')) {
    return NEZHA_SCENARIO.ROLES;
  }

  if (pathname.startsWith('/logmodelview')) {
    return NEZHA_SCENARIO.ACTION_LOG;
  }

  if (pathname.startsWith('/rowlevelsecurity')) {
    return NEZHA_SCENARIO.ROW_LEVEL_SECURITY;
  }

  if (pathname.startsWith('/csstemplatemodelview')) {
    return NEZHA_SCENARIO.CSS_TEMPLATE_MODEL_VIEW;
  }

  if (pathname.startsWith('/nezha/tags')) {
    return NEZHA_SCENARIO.TAGS;
  }

  if (pathname.startsWith('/alert')) {
    return NEZHA_SCENARIO.ALERT;
  }

  if (pathname.startsWith('/report')) {
    return NEZHA_SCENARIO.REPORT;
  }

  if (pathname.startsWith('/nezha/profile/')) {
    return NEZHA_SCENARIO.USER_PROFILE;
  }

  if (pathname.startsWith('/annotationlayer')) {
    return NEZHA_SCENARIO.ANNOTATION_LAYER;
  }

  if (pathname.startsWith('/explore')) {
    if (searchParams?.includes('viz_type=saved_column')) {
      return NEZHA_SCENARIO.SAVED_COLUMN_EXPLORE;
    }

    if (searchParams?.includes('viz_type=calculated_column')) {
      return NEZHA_SCENARIO.CALCULATED_COLUMN_EXPLORE;
    }

    if (searchParams?.includes('viz_type=saved_query')) {
      return NEZHA_SCENARIO.SAVED_QUERY_EXPLORE;
    }

    if (searchParams?.includes('measure_id=')) {
      return NEZHA_SCENARIO.SAVED_MEASURE_EXPLORE;
    }

    if (searchParams?.includes('/cohort/list/')) {
      return NEZHA_SCENARIO.SAVED_COHORT_LIST_EXPLORE;
    }

    if (searchParams?.includes('cohort_id=')) {
      return NEZHA_SCENARIO.SAVED_COHORT_EXPLORE;
    }

    if (
      searchParams?.includes('slice_id=') ||
      searchParams?.includes('viz_type=')
    ) {
      return NEZHA_SCENARIO.CHART_EXPLORE;
    }

    if (searchParams?.includes('datasource_id=')) {
      return NEZHA_SCENARIO.DATASET_EXPLORE;
    }
    return NEZHA_SCENARIO.EXPLORE;
  }

  // if can not match any case, return unclassified
  return NEZHA_SCENARIO.UNCLASSIFIED;
}
